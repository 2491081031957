import React from 'react';
import { useNavigate } from "react-router-dom";

import { Button, Result } from 'antd';
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 8.25%;
`;

export const NotFound: React.FC = () => {

  const navigate = useNavigate();

  return (
    <Container >
      <Result
        status="warning"
        title="Someting Wrong"
        subTitle="There are some problems with your operation."
        extra={
          <Button type="primary" key="console" onClick={() => navigate('/')}>
            Go Home
          </Button>
        }
      />
    </Container >
  )
};