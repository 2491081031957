import React from 'react'

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "react-auth-kit"
// import { RequireAuth } from 'react-auth-kit'

import './index.css';
import App from './App';
import "./i18n/configs";
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>

    <AuthProvider // localstorage cookie
      authType={'localstorage'} authName={'_IC-IDMS_auth'}
      cookieDomain={window.location.hostname} cookieSecure={window.location.protocol === "https:"} >
      <BrowserRouter>

        <App />

      </BrowserRouter>
    </AuthProvider>

  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
