import React, { useState, useEffect } from 'react';

import styles from '../style.module.scss';
// import styled from "styled-components";

import { PlusCircle, ThreeDots } from 'react-bootstrap-icons';

// import { Card, Col, Row, Statistic } from 'antd';
// import { Button } from 'antd';
import { Typography, Badge } from 'antd';
import { Button, Popconfirm, Modal } from 'antd';
import { Input } from 'antd';

import { ConfigProvider } from 'antd';

// Drag and drop feature using DnD
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";

import { TaskCard, TaskCardType } from "./taskCard";

const { Text, Title } = Typography;

export type TaskColumnType = {
  id: string;
  title: string;
  cards: TaskCardType[];

  activeID?: string,
  onTaskUpdate?: any;
  onRowDetailClick?: any;
  onValueChange?: any;
};


// export const TaskCardColumn: React.FC<TaskColumnType> = (props) => {
export const TaskCardColumn: React.FC<TaskColumnType> = (props) => {

  // Const
  // ----------------------------------------------
  const [isLoading, setIsLoading] = useState(true);
  const { setNodeRef } = useDroppable({ id: props.id });
  // const [activeId, setActiveId] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTaskEditModalOpen, setIsTaskEditModalOpen] = useState(false);

  const [taskNotesTextField, setTaskNotesTextField] = useState(String);
  const [taskTextField, setTaskTextField] = useState(String);

  // Value Updates
  // ----------------------------------------------
  const onValueChange = (value: any) => {
    //const value = event.target.value;
    props.onValueChange(value);
    // console.log("Column");
    // console.log(`onValue Props: ${value}`);
  };

  const modalOnTaskUpdate = (value: any) => {
    props.onTaskUpdate(value);
    // console.log("modalOnTaskUpdate");
  }

  const onRowDetailClick = () => {
    // props.onRowDetailClick();
    showTaskEditModal();
    // console.log('onRowDetailClick');
  }

  const isInboxTaskColumn = () => {
    if (props.id == "Task.Inbox") {
      return true;
    } else {
      return false;
    }
  };

  const showModal = () => {
    setTaskTextField('');
    setIsModalOpen(true);
  };
  const showTaskEditModal = () => {
    setTaskTextField('');
    setTaskNotesTextField('');
    setIsTaskEditModalOpen(true);

    console.log(props.activeID);
  };

  const modalOnOK = () => {
    onValueChange(taskTextField);
    setIsModalOpen(false);
  };
  const modalOnCancel = () => {
    setIsModalOpen(false);
    setIsTaskEditModalOpen(false);

    console.log(props.activeID);
  };

  const onTaskTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTaskTextField(e.target.value);
  };
  const onTaskNoteTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTaskNotesTextField(e.target.value);
  };


  return (

    <ConfigProvider theme={{
      token: {
        /* here is your global tokens */
        colorBgMask: "rgba(0, 0, 0, 0.25)",
      },
      components: {
      },
    }}>

      <SortableContext id={props.id} items={props.cards} strategy={rectSortingStrategy}>
        <div ref={setNodeRef} className={styles.taskColumn}>
          <div>
            <span style={{ fontSize: 13, fontWeight: 700, margin: "10px 0px", color: "#777", display: "inline-block" }}>{props.title}</span>
            <Badge count={props.cards.length} showZero color='#83a6c7' style={{ marginLeft: 8 }} />

            {isInboxTaskColumn() ? (() => {
              return (
                <>
                  <Button type="text" icon={<PlusCircle />} onClick={showModal} style={{ fontSize: 16, float: 'right', margin: "4px 4px 0 0" }}></Button>
                  <Modal title="Add Task" open={isModalOpen} onOk={modalOnOK} onCancel={modalOnCancel} closable={false} okText="Save"
                    destroyOnClose keyboard={false} confirmLoading={false}>
                    <Input placeholder="Enter your task" allowClear value={taskTextField || ''} onChange={onTaskTextChange} />
                    <Input placeholder="Add notes" value={taskNotesTextField || ''} onChange={onTaskNoteTextChange} bordered={false} />
                  </Modal>
                </>
              )
            })() : ""}

          </div>
          {props.cards.map((card) => (
            <TaskCard key={card.id} id={card.id} title={card.title} notes={card.notes} assgined={card.assgined}
              completed={card.completed} onDetailClick={onRowDetailClick}></TaskCard>
          ))
          }

          {/* <Modal title="Edit Task" open={isTaskEditModalOpen} closable={false} okText="Save" destroyOnClose
            keyboard={false} confirmLoading={false} footer={[
              <Button key="cancel" type="text" onClick={modalOnCancel}>
                Cancel
              </Button>,
              <Button key="complete" type="text" onClick={modalOnCancel} style={{ fontWeight: 500 }}>
                Mark Complete
              </Button>,
              <Button key="submit" type="text" loading={false} onClick={modalOnTaskUpdate} style={{ fontWeight: 600, color: "#4096ff" }}>
                Update
              </Button>,]}
          >
            <Input placeholder="Enter your task" allowClear value={taskTextField || ''} onChange={onTaskTextChange} />
            <Input placeholder="Add notes" value={taskNotesTextField || ''} onChange={onTaskNoteTextChange} bordered={false} />
            </Modal> */}
        </div>
      </SortableContext>

    </ConfigProvider >
  );
};