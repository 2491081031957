import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig, AxiosResponse } from 'axios'

// import Cookies from 'js-cookie';
// import { v4 as uuidv4 } from 'uuid';

// 環境変数よりエンドポイントを設定 (今回はhttps://jsonplaceholder.typicode.com)
// const baseURL = process.env.NEXT_PUBLIC_APP_API_ENDPOINT;
const baseURL = 'https://servicesws.prod.integered.com/ws/'

const IDMSAuthKey = '_IC-IDMS_auth';
// const cookieToken = Cookies.get(IDMSAuthKey);
let token = localStorage.getItem(IDMSAuthKey);

declare module "axios" {
  export interface AxiosRequestConfig {
    retries?: number;
    retryCount?: number;
  }
}

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

/**
 * デフォルト config の設定
 */
export const axiosClient = axios.create({
  baseURL: baseURL,
  timeout: 35000,
  headers: {
    'Content-Type': 'application/json',
    'X-Integered-Environment': 'prod',
  },
  retries: 3,
  retryCount: 0,
})

/**
 * リクエスト インターセプター
 */
axiosClient.interceptors.request.use((config): any => {
  if (config.headers !== undefined) {
    // --ヘッダにアクセストークンを埋める
    // const accessToken = getAccessToken()
    // if (accessToken) {
    //   config.headers.Authorization = `Bearer ${accessToken}`
    // }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      token = localStorage.getItem(IDMSAuthKey);
      config.headers.Authorization = `Bearer ${token}`
    }
  }
  // alert(cookieToken);
  return config
})

/**
 * レスポンス インターセプター
 */
axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    let errConfig = error.config as AxiosRequestConfig;

    switch (error.response?.status) {
      case 401:
        console.log(error);
        if ((errConfig.retries ?? 0) > (errConfig.retryCount ?? 0)) {
          errConfig.retryCount = (errConfig.retryCount ?? 0) + 1
          return axiosClient.request(errConfig)
        }
        break
      default:

        console.log(error);
        console.log(errConfig);
        if ((errConfig.retries ?? 0) > (errConfig.retryCount ?? 0)) {
          errConfig.retryCount = (errConfig.retryCount ?? 0) + 1
          return axiosClient.request(errConfig)
        }
        break
    }
    return Promise.reject(error)
  }
)