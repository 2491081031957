import React, { useState, useEffect } from 'react';

import { AppHeader } from "../../components/AppHeader";
import { AppFooter } from "../../components/AppFooter";

import { Card, Col, Row } from 'antd';
import { Typography } from 'antd';

import { ConfigProvider } from 'antd';

const { Text } = Typography;


/* type UserProps = {
  project: Array<any>,
  isLoading: boolean
}; */

// export const ProjectDetail: React.FC<UserProps> = (props) => {
export function Settings() {

  return (
    <>
      <ConfigProvider theme={{
        token: {
          /* here is your global tokens */
          padding: 16,
          paddingLG: 16,
        },
        components: {
          Card: {
            actionsLiMargin: "0px",
            extraColor: "#999"
          },
        },
      }}>
        <Row gutter={0}>
          <div>
            <h1>Settings</h1>
          </div>
        </Row>

      </ConfigProvider>
    </>
  )
};