import React, { useState } from 'react';

import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";

import { Login } from "./components/login/login";
import { Signout } from "./components/login/signout";
import { Project } from "./components/project/project";
import { NotFound } from "./components/NotFound";

import { SignatureContext } from './contexts';

import styled from "styled-components";

import './App.scss';
import { ProjectDetail } from './components/project/projectDetail';
import { ProjectList } from './components/project/projectList';
import { Settings } from './components/project/settings';


const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;


const App: React.FC = () => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [personID, setPersonID] = useState('');
  const [projects, setProjects] = useState('');

  return (

    <Routes>

      <Route
        path="/"
        element={
          <RequireAuth loginPath="/idmsauth/signin">

            <SignatureContext.Provider value={{
              firstName, setFirstName,
              lastName, setLastName,
              personID, setPersonID,
              projects, setProjects,
            }}>
              <Project />
            </SignatureContext.Provider>

          </RequireAuth>
        }
      >

        <Route path="project/:projectID" element={<ProjectDetail />} />
        <Route path="setting" element={<Settings />} />
      </Route>

      <Route path={'/idmsauth/signin'} element={<Login />}></Route>
      <Route path={'/idmsauth/signout'} element={<Signout />}></Route>
      <Route path='*' element={<NotFound />} />

    </Routes>
  );
};

export default App;

/*
<AppContainer>
      
    </AppContainer>
 */