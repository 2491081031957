import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { Button, Form, Input, Flex } from 'antd';

import { useSignIn } from "react-auth-kit";
import { useIsAuthenticated } from 'react-auth-kit';

import axios, { AxiosResponse, AxiosError } from "axios";
import { axiosClient } from '../../lib/axiosClient'

// Import ant design component
import { Col, Divider, Row } from 'antd';
import { Layout, Space } from 'antd';
import { Avatar, Badge } from 'antd';
import { Typography } from 'antd';
import { ConfigProvider } from 'antd';

import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { useTranslation } from "react-i18next";


type LayoutType = Parameters<typeof Form>[0]['layout'];
const { Text } = Typography;

type AuthRequestType = {
  accountName: string;
  credential: string;
  extenedSignin?: boolean;
};

type AuthTokenType = {
  authToken: string;
  authType: string;
  extenedSignin: boolean;
};

const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 12.25%;
`;

export const useFetchSignature = (request: AuthRequestType) => {

  const IDMSAuthAccountNameKey = '_IC-IDMS-auth-accountName'
  const [data, setData] = useState<AuthTokenType[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {

    if (request !== null) {
      axiosClient.post(
        "https://signaturews.integered.com/ws/v1/auth/signin/",
        {
          'connectID': request.accountName,
          'connectPassword': request.credential,
          'extenedSignin': request.extenedSignin,
        }
      ).then((res: AxiosResponse) => {
        console.log("fetcher: ", res);
        setData(res.data);
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        setIsFetching(false);
      });
    }
  }, []);

  return {
    token: data,
    isFetching,
  };
};

function Login(props: any) {

  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated()

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidSubmit, setIsValidSubmit] = useState(true);
  // const [blurAminDuration, setBlurAminDuration] = useState(0.825);
  const [error, setError] = useState("");

  const IDMSAuthAccountNameKey = '_IC-IDMS-auth-accountName'
  const [id, setId] = useState(0);
  const { t } = useTranslation();

  /* const { token, isFetching } = useFetchSignature({
    accountName: "shun_yamaguchi",
    credential: "qwerty19",
  });
  console.log(`useFetchSignature: ${token}`); */

  useEffect(() => {
    const savedAccountName = sessionStorage.getItem(IDMSAuthAccountNameKey);
    form.setFieldValue('accountName', savedAccountName);

    if (isAuthenticated()) {
      setIsLoading(true);
      setIsValidSubmit(false);
      // setBlurAminDuration(0);

      setTimeout(() => {
        navigate('/');
      }, 4.5 * 1000);
    } else {
    }
  }, [id]);


  // Handling submit action
  const onSubmit = async (values: any) => {

    setError("");

    setIsLoading(true);
    sessionStorage.setItem(IDMSAuthAccountNameKey, values.accountName)

    try {
      /* axiosClient.post(
        "https://signaturews.integered.com/ws/v1/auth/signin/",
        {
          'connectID': values.accountName,
          'connectPassword': values.credential,
          // 'extenedSignin': values.extenedSignin,
        },
        {
          headers: {
            'X-Integered-App-ID': 'U2F1iX6DmrEgGSHIyRzN0y9QoU9vquQd'
          }
        }
      ).then((res: AxiosResponse) => {
        console.log('AuthRequest response: Successfully authenticated.');

        signIn({
          token: res.data.authToken,
          expiresIn: 60,
          tokenType: "Bearer",
          authState: { accountName: values.accountName },
        });

        navigate('/');
      }).catch((e) => {
        console.log(e);
        // console.log(error.response.status);
      }).finally(() => {
        console.log('AuthRequest completed.');
      }); */

      const headers = {
        'Content-Type': 'application/json',
        'X-Integered-App-ID': 'U2F1iX6DmrEgGSHIyRzN0y9QoU9vquQd',
        'X-Integered-Environment': 'prod',
      }

      const response = await axios.post(

        "https://signaturews.integered.com/ws/v1/auth/signin/",
        {
          'connectID': values.accountName,
          'connectPassword': values.credential,
          'extenedSignin': false,
        },
        { headers: headers },
      ).then(function (res) {

        console.log('Successfully authenticated.');

        signIn({
          token: res.data.authToken,
          expiresIn: 60,
          tokenType: "Bearer",
          authState: { accountName: values.accountName },
        });

        navigate('/');

      });
      /* .catch(function (error) {
        console.log(error.response.status);
      }); */

    } catch (err) {

      if (err && err instanceof AxiosError) {

        let errorMessage = err.response?.data.error.message;
        setError(err.response?.data.error.message);

        switch (err.response?.status) {
          case 401:
            alert(errorMessage);
            break;
          case 403:
            alert(errorMessage);
            break;
          default:
            alert(errorMessage);
            alert("There was some error when attempting to proceed your rewuest. Please try again.");
            break;
        }

      } else if (err && err instanceof Error) {
        setError(err.message);
      }

      setIsLoading(false);
      console.log("Error: ", err);
    }
  };


  const onChange = () => {
    try {
      let value = form.getFieldsValue(['accountName', 'credential']);
      if ((value.accountName?.length >= 2) && (value.credential?.length >= 8)) {
        setIsValidSubmit(false);
      } else {
        setIsValidSubmit(true);
      }
    } catch (err) {
      console.log(err);
    }
  };


  return (

    <ConfigProvider theme={{
      token: {
        /* here is your global tokens */
        colorText: '',
        lineWidth: 1.5,
        colorSplit: 'rgba(5, 5, 5, 0.085)',
      },
      components: {
        Avatar: {
          // fontSize: 17,
        },
      },
    }}>

      <LoginContainer>
        <AnimatePresence>

          <motion.div
            exit={{
              y: -160,
              opacity: 0,
              filter: "blur(5px)",
              transition: { ease: "easeIn", duration: 1.25 }
            }}
            initial={{ opacity: 0, y: -15 }}
            animate={{
              opacity: 1,
              y: 0,
              filter: "blur(0px)",
              transition: { type: "spring", duration: 0.825, delay: 0.25 }
            }}
            className="card"
          >

            <Row gutter={[0, 0]} justify="center" wrap={false} >

              <Col span={6} style={{ margin: '0 0 12px 0' }}>
                <Divider orientation="left" orientationMargin="0" style={{ fontSize: 18, margin: '0 0 22px 0' }}>WEDO｜Signin to Project</Divider>

                <Col span={16} style={{ margin: 'auto' }}>
                  <Form
                    layout="vertical"
                    form={form}
                  // onValuesChange={(_, values) => setFormValues(values)}
                  // onFinish={formik.handleSubmit}
                  >
                    <Text strong style={{ fontSize: 13, color: '#999', lineHeight: 2.2 }}>ACCOUNT NAME</Text>
                    <Form.Item
                      name="accountName"
                      /* rules={[
                        {
                          required: true,
                        },
                      ]} */
                      style={{ marginBottom: '8px' }}
                    >
                      <Input
                        placeholder="" allowClear
                        disabled={isLoading!}
                        onChange={onChange}
                      // onChange={(value) => formik.setFieldValue("accountName", value)}
                      />
                    </Form.Item>

                    <Text strong style={{ fontSize: 13, color: '#999', lineHeight: 2.2 }}>PASSWORD</Text>
                    <Form.Item
                      name="credential"
                    >
                      <Input.Password
                        // value={formik.values.credential}
                        placeholder=""
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        disabled={isLoading!}
                        onChange={onChange}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Flex gap="small" wrap="wrap" justify={'flex-end'}>
                        <Button
                          type="primary" loading={isLoading}
                          disabled={isValidSubmit}
                          onClick={() => onSubmit(form.getFieldsValue(['accountName', 'credential']))}>{t("Login.signin") as string}</Button>
                      </Flex>
                    </Form.Item>
                  </Form>

                </Col>

              </Col>

            </Row >

          </motion.div>
        </AnimatePresence>
      </LoginContainer>

    </ConfigProvider>
  );
}

export { Login };