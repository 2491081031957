import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'

// import axios, { AxiosError } from "axios";
import { axiosClient } from '../axiosClient'

// import { jwtDecode } from "jwt-decode";
// import Cookies from 'js-cookie';

import { Card, Col, Row } from 'antd';
import { Typography, Skeleton } from 'antd';

import { ConfigProvider } from 'antd';

import { FolderOutlined, AppstoreOutlined } from '@ant-design/icons';

const { Text } = Typography;


type UserProps = {
  isLoading: boolean
};

export const ProjectList: React.FC<UserProps> = (props) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState(Array<any>);

  const IDMSAuthKey = '_IC-IDMS_auth';
  // const cookieToken = Cookies.get(IDMSAuthKey);
  let cookieToken = localStorage.getItem(IDMSAuthKey);

  const [id, setId] = useState(0);

  const getProjects = async () => {
    // Retrive Project data
    if (cookieToken !== null) {
      try {
        const response = await axiosClient.get(`v1/project`)
          .then(function (res) {
            let array = res.data as Array<any>;
            setProjects(array);
            setIsLoading(false);
            // console.log(`isLoading: ${isLoading}`);
          });
      } catch (err: any) {
        // throw err;
        console.log(err);
      }
    }
    setId(id + 1);
  }

  // Use Effect
  // ----------------------------------------------
  useEffect(() => {
    if (id <= 1) {
      // const promise = Promise.reject().catch(getProjects).catch(getProjects);
      getProjects();
    }
  }, [id, cookieToken, projects]);

  return (
    <>
      <ConfigProvider theme={{
        token: {
          /* here is your global tokens */
          padding: 16,
          paddingLG: 16,
        },
        components: {
          Card: {
            actionsLiMargin: "0px",
            extraColor: "#999"
          },
        },
      }}>
        <Row gutter={0}>
          {isLoading ? <Skeleton active /> : projects.map((proj) => {
            return (
              <Col span={12}>
                <Card key={proj?.projectID} bordered={false} style={{ margin: '8px', backgroundColor: "#f5f5f6", borderColor: "#ddddde" }}>
                  <Typography.Title level={5} style={{ margin: '0px' }}><AppstoreOutlined /> {proj?.title}</Typography.Title>
                  <Link to={"/project/" + proj?.projectID}><Text code>{proj?.projectID}</Text></Link> <br />
                  <Text>{proj?.description}</Text>
                </Card>
              </Col>
            )
          })
          }
        </Row>
      </ConfigProvider >
    </>
  )
};

/*

*/

/*
{(() => {
            if (isLoading === true) {
              return (
                "No Projects Data..."
              );
            } else {
              projects.map((proj) => {
                return (
                  <Col span={12}>
                    <Card bordered={false} style={{ margin: '8px', backgroundColor: "#f5f5f6", borderColor: "#ddddde" }}>
                      <Typography.Title level={5} style={{ margin: '0px' }}><AppstoreOutlined /> {proj?.title}</Typography.Title>
                      <Text code>{proj?.projectID}</Text> <br />
                      <Text>{proj?.description}</Text>
                    </Card>
                  </Col>
                )
              }
              );
            }
          })()}
 */

/* const headers = {
        'Content-Type': 'application/json',
        // çC'X-Integered-App-ID': 'U2F1iX6DmrEgGSHIyRzN0y9QoU9vquQd',
        'X-Integered-Environment': 'prod',
        'Authorization': `Bearer ${cookieToken}`,
      } */

/* try {
    console.log('Trying to communicate with Projects server...');
    const response = await axios.post(
      "https://servicesws.prod.integered.com/ws/v1/project",
      {}, // Data
      { headers: headers }, // Header
    ).then(function (res) {

      setTimeout(() => {
        let array = res.data as Array<any>;
        setProjects(array);
        setIsLoading(false);
        // console.log(`isLoading: ${isLoading}`);
      }, 1.25 * 1000);
    });
  } catch (err: any) {
    console.log(err);
    // window.location.reload();
  }
  
  await new Promise(resolve => setTimeout(resolve, 3000));
  */