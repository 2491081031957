import React, { useState } from 'react';
import styles from './style.module.scss';

import { Col, Divider, Row } from 'antd';
import { Layout, Space } from 'antd';
import { Avatar, Badge } from 'antd';

import { Typography } from 'antd';

import { ConfigProvider } from 'antd';


const { Text } = Typography;

// <Text style={{ fontSize: '17px', letterSpacing: '-0.085px', color: '#fff', lineHeight: '36px' }}>JA</Text>

type UserProps = {
  name: string;
  badgeCount: number;
};

export const AppHeader: React.FC<UserProps> = (props) => {

  return (
    <ConfigProvider theme={{
      token: {
        /* here is your global tokens */
        colorText: '',
        lineWidth: 2,
        colorSplit: 'rgba(5, 5, 5, 0.085)',
      },
      components: {
        Avatar: {
          // fontSize: 17,
        },
      },
    }}>

      <Row gutter={[16, 0]} justify="center" wrap={false} >

        <Col span={17} style={{ margin: '0 0 12px 0' }}>
          <Divider orientation="left" orientationMargin="0" style={{ margin: '20px 0 0px' }}><span className={styles.appName}>WEDO｜Project </span></Divider>
        </Col>
        <Col>
          <Space size={24} className={styles.avatar}>
            <Badge count={props.badgeCount}>
              <Avatar shape="square" style={{ fontWeight: '600' }}>
                {props.name}
              </Avatar>
            </Badge>
          </Space>
        </Col>

      </Row >

    </ConfigProvider>
  )
};