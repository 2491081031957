import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom'

import { axiosClient } from '../../lib/axiosClient'
import axios, { AxiosError } from "axios";
// import { fetchProject } from '../../lib/scopeWSClient'

import { v4 as uuidv4 } from 'uuid';
// import useInterval from './useInterval';

import { useTranslation } from "react-i18next";


// Design style component
import styles from '../style.module.scss';

import { App, Divider, Progress } from 'antd';
import { Card, Col, Row, Statistic } from 'antd';
import { Typography, Skeleton } from 'antd';
import type { DrawerProps, RadioChangeEvent, UploadFile } from 'antd';
import { Button, Drawer, Radio, Space } from 'antd';
import { Input } from 'antd';
import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';

import { ConfigProvider, theme } from 'antd';

// Drag and move component
import {
  useSensor, useSensors,
  closestCorners, DndContext, DragOverlay, KeyboardSensor, PointerSensor,
  DragEndEvent, DragOverEvent
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { TaskCardColumn, TaskColumnType } from "./taskCardColumn";
import { privateDecrypt, sign } from 'crypto';
// import { TaskCard } from './taskCard';

import { CloudDownload, CloudUpload, Download, FileEarmark, FileEarmarkImage, FileEarmarkPdf, InfoCircle, ListTask, Trash3, XLg } from 'react-bootstrap-icons';
import TextArea from 'antd/es/input/TextArea';
import { TaskCardType } from './taskCard';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;
const { Dragger } = Upload


const IDMSAuthKey = '_IC-IDMS_auth';
let authToken = localStorage.getItem(IDMSAuthKey);


// export const ProjectDetail: React.FC<UserProps> = (props) => {
export function ProjectDetail() {

  // Const
  // ----------------------------------------------
  const urlParams = useParams<{ projectID: string }>()

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({
    title: "", description: "", status: "", endDate: 0, tasks: []
  });

  const [isTaskEditModalOpen, setIsTaskEditModalOpen] = useState(false);
  const [activeTaskID, setActiveTaskID] = useState("");

  const [taskTextField, setTaskTextField] = useState(String);
  const [taskNotesTextField, setTaskNotesTextField] = useState(String);
  const [isTaskCompleted, setIsTaskCompleted] = useState(Boolean);

  const [uploadFiles, setUploadFiles] = useState<any>([]);

  const { token } = theme.useToken();
  const { t } = useTranslation();

  const IDMSAuthKey = '_IC-IDMS_auth';
  let authToken = localStorage.getItem(IDMSAuthKey);

  // Drawer view feature
  // ----------------------------------------------
  //
  const showTaskEditDrawer = () => {
    // alert("OK");
    setIsTaskEditModalOpen(true);
  };

  const onTaskEditDrawerClose = () => {
    setIsTaskEditModalOpen(false);
  };

  // Drag and Drop feature
  // ----------------------------------------------

  // Project task data
  const data: TaskColumnType[] = [
    {
      id: "Task.Inbox", title: "インボックス", cards: [
      ]
    },
    {
      id: "Task.Progress", title: "進行中", cards: [
      ]
    }
  ];

  const [columns, setColumns] = useState<TaskColumnType[]>(data);
  const [activeId, setActiveId] = useState(null);
  let aID = "";

  const findColumn = (unique: string | null) => {
    if (!unique) {
      return null;
    }
    // overの対象がcolumnの場合があるためそのままidを返す
    if (columns.some((c) => c.id === unique)) {
      return columns.find((c) => c.id === unique) ?? null;
    }
    const id = String(unique);
    const itemWithColumnId = columns.flatMap((c) => {
      const columnId = c.id;
      return c.cards.map((i) => ({ itemId: i.id, columnId: columnId }));
    });
    const columnId = itemWithColumnId.find((i) => i.itemId === id)?.columnId;
    return columns.find((c) => c.id === columnId) ?? null;
  };

  const handleDragOver = (event: DragOverEvent) => {

    const { active, over, delta } = event;
    const activeId = String(active.id);

    setActiveTaskID(`${activeId}`);
    findTask(activeId);
    setIsTaskEditModalOpen(true);

    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);

    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return null;
    }
    setColumns((prevState) => {
      const activeItems = activeColumn.cards;
      const overItems = overColumn.cards;
      const activeIndex = activeItems.findIndex((i) => i.id === activeId);
      const overIndex = overItems.findIndex((i) => i.id === overId);
      const newIndex = () => {
        const putOnBelowLastItem =
          overIndex === overItems.length - 1 && delta.y > 0;
        const modifier = putOnBelowLastItem ? 1 : 0;
        return overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      };
      return prevState.map((c) => {
        if (c.id === activeColumn.id) {
          c.cards = activeItems.filter((i) => i.id !== activeId);
          return c;
        } else if (c.id === overColumn.id) {
          c.cards = [
            ...overItems.slice(0, newIndex()),
            activeItems[activeIndex],
            ...overItems.slice(newIndex(), overItems.length)
          ];
          return c;
        } else {
          return c;
        }
      });
    });
  };

  const handleDragEnd = async (event: DragEndEvent) => {

    const { active, over } = event;
    const activeId = String(active.id);
    const overId = over ? String(over.id) : null;
    const activeColumn = findColumn(activeId);
    const overColumn = findColumn(overId);
    if (!activeColumn || !overColumn || activeColumn !== overColumn) {
      return null;
    }
    const activeIndex = activeColumn.cards.findIndex((i) => i.id === activeId);
    const overIndex = overColumn.cards.findIndex((i) => i.id === overId);

    if (activeIndex !== overIndex) {
      setColumns((prevState) => {
        return prevState.map((column) => {
          if (column.id === activeColumn.id) {
            column.cards = arrayMove(overColumn.cards, activeIndex, overIndex);
            return column;
          } else {
            return column;
          }
        });
      });
    }

    // Update API
    let c = JSON.stringify(columns);
    let _currentProject = project;
    try {
      const response = await axiosClient.put(
        `v1/project/${urlParams.projectID}`,
        _currentProject
      ).then(function (res) {
        let array = res.data;
        setProject(array);
        setColumns(array.tasks);
        // console.log(`isLoading: ${isLoading}`);
      });
    } catch (err: any) {
      // throw err;
      console.log(err);
    }

    if (event.delta.x !== 0 && event.delta.y !== 0) {
      onTaskEditDrawerClose();
    }
  };

  // Task drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  // Task and progress feature
  // ----------------------------------------------

  const inboxTaskColum = () => {
    let _inboxTask = project.tasks.find(({ id }) => id == "Task.Inbox") as unknown;
    let _inboxTaskColum = _inboxTask as TaskColumnType;
    return _inboxTaskColum;
  }

  const progressTaskColum = () => {
    let _progressTask = project.tasks.find(({ id }) => id == "Task.Progress") as unknown;
    let _progressTaskColum = _progressTask as TaskColumnType;
    return _progressTaskColum;
  }

  const taskProgressPercentage = () => {
    let inboxTaskCount = inboxTaskColum().cards as Array<any>;
    let progressTaskCount = progressTaskColum().cards as Array<any>;

    let allCount = inboxTaskCount.length + progressTaskCount.length;
    let p = (progressTaskCount.length / allCount * 100);
    if (isNaN(p)) {
      p = 0;
    }
    return Math.round(p);
  };


  const findTask = (taskID: string) => {
    let mergedArray = inboxTaskColum().cards.concat(progressTaskColum().cards);
    let _selectedTask = mergedArray.find(({ id }) => id == taskID) as TaskCardType;

    if (_selectedTask != null) {
      setTaskTextField(_selectedTask.title);
      setTaskNotesTextField(_selectedTask.notes as string);
      setIsTaskCompleted(_selectedTask.completed as boolean);
    }
    return _selectedTask;
  }

  const updateTask = (taskID: string, title?: any, notes?: any, complete?: boolean) => {

    const columnArray = [inboxTaskColum, progressTaskColum];
    columnArray.map((column: any) => {
      column().cards.map((task: TaskCardType) => {
        if (task.id === taskID) {
          // Update task title
          let taskTitle = title;
          if (title !== null && title.length > 0) {
          } else {
            taskTitle = t("Placeholder.NewTask")
          }
          task.title = taskTitle;
          // Update notes
          task.notes = notes;
          task.completed = complete;
        }
      });
    });

    postUpdateTask(`${urlParams.projectID}`, project);
  }

  const postUpdateTask = async (_projectID: string, _project: any) => {

    // Update API
    let _currentProject = _project;
    try {
      const response = await axiosClient.put(
        `v1/project/${_projectID}`,
        _currentProject
      ).then(function (res) {
        let array = res.data;
        setProject(array);
        setColumns(array.tasks);
        // console.log(`isLoading: ${isLoading}`);
      });
    } catch (err: any) {
      // throw err;
      console.log(err);
    }
  }

  const onTaskTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTaskTextField(e.target.value);
  };
  const onTaskNoteTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTaskNotesTextField(e.target.value);
  };
  const onTaskUpdateClick = () => {
    updateTask(activeTaskID, taskTextField, taskNotesTextField);
    // postUpdateTask(project);
  }
  const onTaskDeleteClick = () => {
    let targetTask = findTask(activeTaskID);
    const columnArray = [inboxTaskColum, progressTaskColum];
    columnArray.map((column: any) => {
      /* column().cards.map((task: TaskCardType) => {
        if (task === targetTask) {
          console.log(targetTask);
        }
      }); */
      const index = column().cards.indexOf(targetTask);
      if (index >= 0) {
        if (index !== -1) {
          column().cards.splice(index, 1);
        }
      }
    });
    postUpdateTask(`${urlParams.projectID}`, project);
    onTaskEditDrawerClose();
  }

  const onTaskCompleteChange = (e: CheckboxChangeEvent) => {
    // console.log(`checked = ${e.target.checked}`);
    // updateTask(activeTaskID, taskTextField, taskNotesTextField);
    updateTask(activeTaskID, taskTextField, taskNotesTextField, e.target.checked);

    setTimeout(() => {
      onTaskEditDrawerClose();
    }, 0.25 * 1000);
  };

  // Add new task
  const addTaskFromCom = async (title: string) => {

    let taskTitle = title;
    if (title.length <= 0) {
      taskTitle = "New Task"
    }

    let _currentProject = project;
    let _inboxTask = _currentProject.tasks.find(({ id }) => id == "Task.Inbox") as unknown;
    // let _progressTask = _currentProject.tasks.find(({ id }) => id == "Task.Progress") as unknown;
    let _inboxTaskColum = _inboxTask as TaskColumnType;
    _inboxTaskColum.cards.push({
      title: taskTitle, notes: "", assgined: "",
      completed: false, id: `${uuidv4()}`
    });
    // let _newTasks = [_inboxTaskColum, _progressTask];

    try {
      const response = await axiosClient.put(
        `v1/project/${urlParams.projectID}`,
        _currentProject
      ).then(function (res) {
        let array = res.data;
        setProject(array);
        setColumns(array.tasks);
        // console.log(`isLoading: ${isLoading}`);
      });
    } catch (err: any) {
      // throw err;
      console.log(err);
    }
  }

  // File upload feature
  // ----------------------------------------------

  const putUploadFile = async (_projectID: string, _uploadFileList: any) => {

    // Put Upload Files to API
    try {
      const response = await axiosClient.put(
        `v1/project/${urlParams.projectID}/files`,
        _uploadFileList
      ).then(function (res) {
        let array = res.data;
        // console.log(array);
        console.log(`Putting uploaded files list...`);
        // console.log(_uploadFileList);
      });
    } catch (err: any) {
      console.log(err);
    }
  }

  const deleteUploadFile = async (_projectID: string, _keyUID: string) => {
    try {
      /* const response = await axiosClient.delete(
        `v1/project/${urlParams.projectID}/files`,
        {
          "keyID": `${_keyUID}`
        }
      ).then(function (res) {
        let array = res.data;
        console.log(`Deleted uploaded files.`);
      }); */

      try {
        const response = await axiosClient.delete(
          `v1/project/${urlParams.projectID}/files`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authToken}`,
              'X-Integered-Environment': 'prod'
            },
            data: {
              "keyID": `${_keyUID}`
            }
          }
        ).then(function (res) {
          console.log(res);
        });
      } catch (err: any) {
        console.log(err);
      }
      /* axios.delete(`v1/project/${urlParams.projectID}/files`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
          'X-Integered-Environment': 'prod'
        },
        data: {
          "keyID": `${_keyUID}`
        }
      }).then(function (res) {
        // let array = res.data;
        // console.log(`Deleted uploaded files.`, res);
      }); */

    } catch (err: any) {
      console.log(err);
    }
  }

  const uploadProps: UploadProps = {
    name: 'attached_file',
    multiple: true,
    action: `https://servicesws.prod.integered.com/ws/v1/project/${urlParams.projectID}/files`,
    headers: {
      // 'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    showUploadList: true,
    fileList: uploadFiles,
    async onChange(info) {

      const { status } = info.file;
      if (status === 'uploading') {
        console.log("onChange - uploading: ", info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);

        /* try {
          const response = await axiosClient.put(
            `v1/project/${urlParams.projectID}/files`,
            info.fileList
          ).then(function (res) {
            let array = res.data;
            console.log(array);
            console.log(`uploading files to server...`);
            console.log(info.fileList);
          });
        } catch (err: any) {
          console.log(err);
        } */
        let projectID = urlParams.projectID as string;
        putUploadFile(projectID, info.fileList);

        console.log(uploadFiles);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }

      // Always set state in last.
      setUploadFiles(info.fileList);
    },
    onDrop(e) {
      console.log('Dropped files.', e.dataTransfer.files);
    },
    onRemove(file) {

      let _uploadFileList = uploadFiles;
      let _keyUID = "";

      uploadFiles.map((f: any) => {
        if (f.uid === file.uid) {
          let index = uploadFiles.indexOf(f);
          _uploadFileList.splice(index, 1);
          _keyUID = f.uid;
          // console.log("Deleted", f);
        }
      });

      let projectID = urlParams.projectID as string;
      deleteUploadFile(projectID, _keyUID);
      // putUploadFile(projectID, _uploadFileList);

      // Always set state in last.
      setUploadFiles(_uploadFileList);
      console.log('File removed.', uploadFiles);
    },
    onDownload(file) {
      // console.log("Download", file.response.status, file);
      axios({
        url: file.response.fileURL,
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          const href = window.URL.createObjectURL(response.data);

          const anchorElement = document.createElement('a');

          anchorElement.href = href;
          anchorElement.download = file.response.fileName;

          document.body.appendChild(anchorElement);
          anchorElement.click();

          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        })
        .catch(error => {
          alert(`${error.message} - ${error.code} occured. Please try again.`);
          console.log('error: ', error);
          console.log(error.status);
        });
    }
  };

  // Use Effect
  // ----------------------------------------------
  const [id, setId] = useState(0);

  // Retrive project details
  const getProjectDetail = async () => {
    // Retrive Project data
    if (token !== null) {
      try {
        const response = await axiosClient.get(`v1/project/${urlParams.projectID}`)
          .then(function (res) {
            let array = res.data;
            setProject(array);
            setColumns(array.tasks);

            setUploadFiles(array.uploadFiles);

            setIsLoading(false);
            // console.log(array.tasks);
            // console.log(`isLoading: ${isLoading}`);
          });
      } catch (err: any) {
        // throw err;
        console.log(err);
      }
    }
    setId(id + 1);
  }

  useEffect(() => {
    if (id < 1) {
      getProjectDetail();

      const interval = setInterval(() => {
        getProjectDetail();
      }, 30 * 1000);

      // console.log("uploadFiles: ", uploadFiles);
      // let p = fetchProject(`${urlParams.projectID}`);
      // console.log(`fetchProject: ${p}`);
      // return () => clearInterval(interval);
    }
  }, [id]);

  return (
    <>
      <ConfigProvider theme={{
        token: {
          /* here is your global tokens */
          padding: 0,
          paddingLG: 16,
          fontSize: 14,
          fontSizeLG: 13,
        },
        components: {
          Card: {
            actionsLiMargin: "0px",
            extraColor: "#888",
          },
          Descriptions: {
            titleMarginBottom: 8,
            titleColor: "#999"
          },
          Statistic: {
            titleFontSize: 13,
            contentFontSize: 16,
          },
        },
      }}>
        <App>

          {isLoading ? <Skeleton active /> : (() => {
            let endDate = new Date(project.endDate * 1000);
            let projTerm = endDate.toDateString();
            return (
              <>
                <Title level={3} style={{ marginTop: 0, marginBottom: 8 }}>{project?.title}</Title>
                <Text style={{ paddingBottom: 16, display: "block" }}>{project?.description}</Text>

                <Row gutter={18} style={{ marginBottom: 16 }}>
                  <Col span={6}>
                    <Statistic title={t("Project.Description.Status") as string} value={project?.status} />
                  </Col>
                  <Col span={6}>
                    <Statistic title="Project Term" value={projTerm} />
                  </Col>
                  <Col span={6}>
                    <Statistic title="Project Progress" value={`${taskProgressPercentage()}%`} />
                  </Col>
                </Row>

                <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}>
                  <Row gutter={18}>

                    {columns.map((column) => (
                      <Col key={column.id} span={12}>
                        <TaskCardColumn
                          key={column.id}
                          id={column.id}
                          title={column.title}
                          cards={column.cards}
                          activeID={activeTaskID}
                          onTaskUpdate={updateTask}
                          onRowDetailClick={showTaskEditDrawer}
                          onValueChange={addTaskFromCom}
                        ></TaskCardColumn>
                      </Col>
                    ))}
                  </Row>
                </DndContext>

                <ConfigProvider theme={{
                  token: {

                  }
                }}>
                  <Divider orientation="left" plain orientationMargin="0" style={{ color: "#888", marginBottom: 12 }}>
                    Files
                  </Divider>

                  <div style={{ fontSize: 13, color: "#777" }}>
                    <div style={{ padding: "0 12px 0" }}>
                      Below files are visible: <Link to={"https://localhost:3000/file/collection/"}>
                        <Text code>https://servicesws.prod.integered.com/file/collection/</Text></Link> Password:
                      <Text code>5FfBGDLdbZ2DNb30</Text>
                    </div>
                    <div>
                    </div>
                  </div>

                  <div style={{ marginTop: 18 }}>
                    <Dragger {...uploadProps} listType="picture"
                      className={"upload-list-inline"} style={{ color: "#666" }}
                      itemRender={
                        (originNode, file, fileList, { preview, download, remove }) => {
                          return (
                            <div className="custom-item-renderer">
                              <div className='flex-wrap'>
                                <span className="file-icon">
                                  {
                                    (() => {
                                      if (file.type?.includes("image")) {
                                        return (<FileEarmarkImage />);
                                      } else if (file.type?.includes("pdf")) {
                                        return (<FileEarmarkPdf />);
                                      } else {
                                        return (<FileEarmark />);
                                      }
                                    })()
                                  }
                                </span>
                                <span style={{ fontSize: 12 }}>{file.name}</span>
                              </div>
                              <div className="button-group">
                                {
                                  (() => {
                                    if (file.status !== 'done') {
                                      return (<Progress percent={file.percent} showInfo={false} />)
                                    }
                                  })()
                                }
                                <Button type="text" shape="round" icon={<Trash3 />} size={'middle'} onClick={() => {
                                  var result = window.confirm(t("Project.File.DeleteFileConfirmation") as string);
                                  if (result) {
                                    remove();
                                  }
                                }}
                                  style={{ padding: "4px 8px 0", marginRight: 0 }} />
                                <Button type="text" shape="round" icon={<Download />} size={'middle'} onClick={() => { download(); }}
                                  style={{ padding: "2px 8px 0" }} />
                              </div>
                            </div>
                          );
                        }
                      }
                    >
                      <p className="ant-upload-drag-icon" style={{ fontSize: 38, margin: "14px 0 0 0" }} >
                        <CloudUpload />
                      </p>
                      <p className="ant-upload-hint" style={{ marginTop: 0 }}>
                        Click or drag file to this area to upload. Support for a single or bulk upload.
                      </p>
                    </Dragger>
                  </div>
                </ConfigProvider>

              </>
            )
          })()
          }

        </App>

      </ConfigProvider >

      <ConfigProvider theme={{
        token: {
          fontSizeLG: 16,
        }
      }}>

        <Drawer
          title={"Edit Task"} placement={'right'}
          onClose={onTaskEditDrawerClose}
          open={isTaskEditModalOpen} key={'bottom'} mask={false} height={220} width={320} headerStyle={{ padding: 8 }}>
          {/* <ListTask size={18} style={{ marginTop: 4 }} /> */}

          <TextArea placeholder="Enter your task" allowClear autoSize={{ minRows: 1, maxRows: 5 }}
            value={taskTextField || ''} onChange={onTaskTextChange} bordered={false} style={{ fontSize: 16 }} className={styles.taskEditTitle}
            count={{ show: true, max: 120 }} disabled={isTaskCompleted} />
          <TextArea placeholder="Add notes" autoSize={{ minRows: 3, maxRows: 10 }} value={taskNotesTextField || ''}
            onChange={onTaskNoteTextChange} bordered={false} className={styles.taskEditNotes} disabled={isTaskCompleted} />
          <Checkbox onChange={onTaskCompleteChange} checked={isTaskCompleted}>{t("Project.Task.MarkComplete") as string}</Checkbox>

          <div style={{ marginTop: 16 }}>
            <Button key="complete" type="text" danger onClick={onTaskDeleteClick} style={{ fontWeight: 500 }}>
              {t("Project.Task.Delete") as string}
            </Button>
            <Button key="submit" type="text" loading={false} onClick={onTaskUpdateClick} style={{ fontWeight: 600, color: "#4096ff" }}>
              Update
            </Button>
          </div>
        </Drawer>

      </ConfigProvider >
    </>
  )
};

// PROJECT DETAILS
/*
<Row gutter={0}>
          <div>
            <Title level={3} style={{ marginTop: 0, marginBottom: 8 }}>Cannondale Web Dev</Title>
            <Text style={{ paddingBottom: 16, display: "block" }}>Project Description.</Text>

            <Descriptions title="PROJECT DETAILS" items={items} />
          </div>
        </Row>


        <TaskCard />

*/

/*

*/