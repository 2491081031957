import React, { useState, createContext } from 'react';

import { useSignOut } from "react-auth-kit";
import { useIsAuthenticated } from 'react-auth-kit';

import axios, { AxiosError } from "axios";

/* type SignatureUserContext = {
  firstName: string | null;
};
export const SignatureUserContext = createContext<SignatureUserContext>({} as SignatureUserContext); */

export const SignatureContext = React.createContext({} as {
  firstName: string,
  setFirstName: React.Dispatch<React.SetStateAction<string>>,
  lastName: string,
  setLastName: React.Dispatch<React.SetStateAction<string>>,

  personID: string,
  setPersonID: React.Dispatch<React.SetStateAction<string>>,

  projects: any,
  setProjects: React.Dispatch<React.SetStateAction<any>>,
})