import React, { useState, useEffect, Children } from 'react';

import { Button } from 'antd';
import { PlusCircle, ThreeDots, Check2Square } from 'react-bootstrap-icons';

import styles from '../style.module.scss';

import { Typography } from 'antd';

import { ConfigProvider } from 'antd';

// Drag and drop feature using DnD
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { sign } from 'crypto';

const { Text } = Typography;

export type TaskCardType = {
  id: string;
  title: string;
  notes?: string;
  assgined?: string;
  completed?: boolean;
  onDetailClick?: any;
};

export const TaskCard: React.FC<TaskCardType> = (props) => {

  const showModal = () => {
    console.log("AAAA");
  }

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: props.id
  });

  const style = {
    transform: CSS.Transform.toString(transform)
  };

  return (
    // attributes、listenersはDOMイベントを検知するために利用します。
    // listenersを任意の領域に付与することで、ドラッグするためのハンドルを作ることもできます。
    <>
      <div className={styles.taskRowContainer}>
        <div ref={setNodeRef} {...attributes} {...listeners} style={style} className={`${styles.taskRow} ${props.completed && styles.taskCompleted}`}>
          {props.completed && <span className={styles.taskCompleteBox}><Check2Square /></span>}
          <div id={props.id} className={styles.taskRowContent}>
            <Text>{props.title}</Text>
            <Text style={{ fontSize: 12, fontWeight: 500, color: "#777", display: 'block', lineHeight: "0.7 !important" }}>{props.notes || " "}</Text>
          </div>
        </div>
        {/* 
        <span className={styles.taskRowDetail}>
          <Button type="text" icon={<ThreeDots />} onClick={props.onDetailClick} style={{ fontSize: 16, float: 'right', margin: "5px 0 0 8px" }}></Button>
        </span>
        */}
      </div>
    </>
  );
};