'use client'
import React, { useState, useRef, useEffect, useContext } from 'react';

import { useSignOut, useAuthUser, useIsAuthenticated } from "react-auth-kit";

import { useNavigate } from "react-router-dom";
import { Outlet, useOutlet } from 'react-router-dom'
import { Link } from 'react-router-dom'

import useSWR from "swr";
import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import { axiosClient } from '../../lib/axiosClient'

import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';

import { SignatureContext } from '../../contexts';

// Import shared component
import { AppHeader } from "../../components/AppHeader";
import { AppFooter } from "../../components/AppFooter";
import { ProjectList } from "./projectList";
import { ProjectDetail } from "./projectDetail";

// Import app design component
import { Button, Form, Input, Flex, FloatButton } from 'antd';
import { Col, Divider, Row } from 'antd';
import { Menu, MenuProps } from 'antd';
import { Layout, Space } from 'antd';
import { Avatar, Badge } from 'antd';

import { Typography, Skeleton } from 'antd';
import { ConfigProvider, theme } from 'antd';

import { Folder, Gear } from 'react-bootstrap-icons';

import { QuestionCircleOutlined, SyncOutlined, PoweroffOutlined } from '@ant-design/icons';

import styled from "styled-components";

// import { serviceClient } from "../../lib/serviceClient";

const { Text } = Typography;
const { Header, Content, Footer, Sider } = Layout;


// 
// ----------------------------------------------

type userInfo = {
  personID: string,
  firstName: string,
  lastName: string,
}

function useUserInfo() {

  const IDMSAuthKey = '_IC-IDMS_auth';
  let authToken = localStorage.getItem(IDMSAuthKey);

  const baseURL = 'https://signaturews.integered.com/ws/'

  const fetcher = <T,>(url: string) => {
    axiosClient.post(
      url,
      {}
    ).then((res: AxiosResponse) => {
      console.log("fetcher: ", res.data);
      return res.data;
    });
  }

  const { data, error, isLoading } = useSWR(`${baseURL}v1/account/info`, fetcher)

  return {
    user: data
  };
}

// 
// ----------------------------------------------

type Dictionary<TKey extends string | number | symbol, TValue> = {
  [key in TKey]: TValue;
};
export default Dictionary;

/*
  menuItem
*/
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [
  getItem('Projects', 'project', <Folder size={19} />),

  { type: 'divider' },

  getItem('Settings', 'setting', <Gear size={19} />),
];

function Project() {
  // const Project: React.FC = () => {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const singOut = useSignOut();
  const auth = useAuthUser();

  const {
    firstName, setFirstName,
    lastName, setLastName,
    personID, setPersonID,
    projects, setProjects,
  } = useContext(SignatureContext)

  const { user } = useUserInfo();

  const [isLoading, setIsLoading] = useState(true);

  const outlet = useOutlet()

  const navigate = useNavigate();

  const IDMSAuthKey = '_IC-IDMS_auth'

  const isAuthenticated = useIsAuthenticated()
  if (isAuthenticated()) {
    // navigate('/project');
  }

  /*
    useEffect()
   */
  const [id, setId] = useState(0);
  useEffect(() => {

    const getDirectory = async () => {

      // Get token and store personID
      // const token = Cookies.get(IDMSAuthKey);
      const token = localStorage.getItem(IDMSAuthKey);

      if (token != null) {
        const decodedToken = jwtDecode(JSON.stringify(token));
        let decoded = decodedToken as Array<string>;
        let parsedToken = JSON.parse(JSON.stringify(decoded));
        let personID = parsedToken.personID;

        const headers = {
          'Content-Type': 'application/json',
          'X-Integered-App-ID': 'U2F1iX6DmrEgGSHIyRzN0y9QoU9vquQd',
          'X-Integered-Environment': 'prod',
          'Authorization': `Bearer ${token}`,
        }

        // Retrive user data
        try {
          const response = await axios.post(
            "https://signaturews.integered.com/ws/v1/account/info",
            {},
            { headers: headers },
          ).then(function (res) {
            let directoryInfo = res.data.directoryInfo;
            setFirstName(directoryInfo.firstName);
            setLastName(directoryInfo.lastName);
            setPersonID(directoryInfo.personID);
          });
        } catch (err) {
        }
      }
    }
    getDirectory();

  }, [id, isLoading]);


  /*
    internalFunctions
   */
  let userInitial = `${firstName.substring(0, 1)}${lastName.substring(0, 1)}`

  const onClick: MenuProps['onClick'] = (e) => {
    // console.log('click ', e);

    switch (e.key) {
      case "project":
        navigate("/");
        break;
      case "setting":
        navigate("/setting");
        break;
      default:
        navigate("/");
        break;
    }
  };

  const signOutOnClick: MenuProps['onClick'] = (e) => {
    alert('Sign-out proceed.');
    singOut();
  };

  const logout = () => {
    alert('Sign-out proceed.');
    singOut();
    // navigate("/");
  };

  return (
    <>
      <ConfigProvider theme={{
        token: {
          /* here is your global tokens */
          colorText: '',
        },
        components: {
          Layout: {
            /* here is your component tokens */
            bodyBg: 'transparent',
            footerBg: 'transparent',
          },
          Menu: {
            fontSize: 13,
            margin: 8,
            padding: 8,
            borderRadius: 2,
            subMenuItemBorderRadius: 2,
            itemPaddingInline: 8,
            itemMarginInline: 6,
            itemHeight: 32,
            groupTitleLineHeight: 0.7,
            groupTitleFontSize: 13,
            iconSize: 17,
          },
        },
      }}>
        <Layout>

          <AppHeader name={userInitial} badgeCount={0} />

          <Row justify="center" wrap={false}>
            <Col span={18}>

              <Content style={{ padding: '0 18px' }}>

                <Layout
                  style={{
                    padding: '12px 0',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}>

                  <Sider style={{
                    background: colorBgContainer,
                    margin: '0 0 0 4px',
                  }}
                    width={250}>

                    <Menu
                      onClick={onClick}
                      style={{ width: 256 }}
                      defaultSelectedKeys={['project']}
                      defaultOpenKeys={['project']}
                      mode="inline"
                      items={items}
                    />

                  </Sider>

                  <Content

                    style={{
                      padding: '0 24px 0 32px',
                      minHeight: 440,
                    }}
                  >
                    {outlet || <ProjectList isLoading={isLoading} />}

                  </Content>

                </Layout>

              </Content>
            </Col>
          </Row>

          {/* <AppFooter firstName={firstName} lastName={lastName} /> */}

        </Layout>

        <FloatButton.Group shape="square" style={{ right: 94 }}>
          <FloatButton icon={<QuestionCircleOutlined />} />
          <FloatButton icon={<PoweroffOutlined />} onClick={() => navigate('/idmsauth/signout')} />
          <FloatButton.BackTop visibilityHeight={0} />

        </FloatButton.Group>

      </ConfigProvider>
    </>
  );
}

// {isLoading ? <Skeleton active /> : <ProjectList isLoading={isLoading} /* projects={[{ item: "AAA" }]}*/ projects={projects} />}
// {outlet?.props.children || isLoading ? <Skeleton active /> : <ProjectList isLoading={isLoading} /* projects={[{ item: "AAA" }]}*/ projects={projects} />}


export { Project };
// export default Project;