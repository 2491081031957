import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { Button, Form, Result } from 'antd';

import { useSignOut } from "react-auth-kit";
import { useIsAuthenticated } from 'react-auth-kit';

import axios, { AxiosError } from "axios";
// import { useFormik } from "formik";

import { ConfigProvider } from 'antd';

import styled from "styled-components";


const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 8.25%;
`;

function Signout(props: any) {

  // const signIn = useSignIn();
  const singOut = useSignOut();
  const isAuthenticated = useIsAuthenticated()

  const navigate = useNavigate();

  const [id, setId] = useState(0);
  useEffect(() => {
    if (isAuthenticated()!) {
      singOut();
      setTimeout(() => {
        navigate('/');
      }, 1500);
    } else {
    }
  }, [id]);

  return (

    <ConfigProvider theme={{
      token: {
        /* here is your global tokens */
        colorText: '',
        lineWidth: 1.5,
        colorSplit: 'rgba(5, 5, 5, 0.085)',
      },
      components: {
        Avatar: {
          // fontSize: 17,
        },
      },
    }}>

      <Container>

        <Result
          status="success"
          title="Successfully Sign-out"
          subTitle="You have been sign-out successfully. It will redirect to main page in a few secounds."
          extra={[
            <Button key="signin-again" onClick={() => navigate('/idmsauth/signin')}>Sign-in Again</Button>,
          ]}
        />

      </Container>

    </ConfigProvider>
  );
}

export { Signout };